import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'
import Inscription from './components/Inscription/Inscription';
import ModalCdl from './components/ModalCdl/ModalCdl';
import SubTitle from './components/SubTitle/SubTitle';
import useApi from './hooks/useApi';

export default function App() {

  // States Modal
  const [vendredi, setVendredi] = useState(false);
  const [samedi, setSamedi] = useState(false);
  const [ouverture, setOuverture] = useState(false);
  const [parking, setParking] = useState(false);
  const [inscriptions, setInscriptions] = useState(false);
  const [security1, setSecurity1] = useState(false);
  const [security2, setSecurity2] = useState(false);
  const [security3, setSecurity3] = useState(false);
  const [lavage, setLavage] = useState(false);
  const [sandwich, setSandwich] = useState(false);
  const [ravito1, setRavito1] = useState(false);
  const [ravito2, setRavito2] = useState(false);
  const [ravito3, setRavito3] = useState(false);
  const [cochon, setCochon] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  // S'il y a eu un ajout ou suppression d'un user
  const [sub, setSub] = useState(false);

  const [loading, setLoading] = useState(true);

  // List des tables
  const [vendrediList, setVendrediList] = useState([]);
  const [samediList, setSamediList] = useState([]);
  const [ouvertureList, setOuvertureList] = useState([]);
  const [inscriptionsList, setInscriptionsList] = useState([]);
  const [cochonList, setCochonList] = useState([]);
  const [lavageList, setLavageList] = useState([]);
  const [parkingList, setParkingList] = useState([]);
  const [ravito1List, setRavito1List] = useState([]);
  const [ravito2List, setRavito2List] = useState([]);
  const [ravito3List, setRavito3List] = useState([]);
  const [sandwichList, setSandwichList] = useState([]);
  const [security1List, setSecurity1List] = useState([]);
  const [security2List, setSecurity2List] = useState([]);
  const [security3List, setSecurity3List] = useState([]);

  const [showAlert, setShowAlert] = useState(false);

  const { loadSubscribers, deleteSubscriberDB} = useApi();

  const loadAllSubscribers = async () => {
    await loadSubscribers('vendredis', setVendrediList);
    await loadSubscribers('samedis', setSamediList);
    await loadSubscribers('ouvertures', setOuvertureList);
    await loadSubscribers('cochons', setCochonList);
    await loadSubscribers('inscriptions', setInscriptionsList);
    await loadSubscribers('lavages', setLavageList);
    await loadSubscribers('parkings', setParkingList);
    await loadSubscribers('first_ravitos', setRavito1List);
    await loadSubscribers('second_ravitos', setRavito2List);
    await loadSubscribers('third_ravitos', setRavito3List);
    await loadSubscribers('sandwiches', setSandwichList);
    await loadSubscribers('security_sites', setSecurity1List);
    await loadSubscribers('security_circuits', setSecurity2List);
    await loadSubscribers('security_passages', setSecurity3List);
    setLoading(false);
  }

  const loadSubscibersPoste = async (poste, setPosteList) => {
    await loadSubscribers(poste, setPosteList);
    // setLoading(false);
  }

  useEffect(() => {
    loadAllSubscribers();
  },[loading])

  useEffect(() => {
    if (sub) {
      // setLoading(true);
      loadSubscibersPoste(sub.poste, sub.setPosteList);
      setSub(false);
      setShowAlert(`${sub.user} a bien ajouté à ${sub.title} ! Merci !`);
    }
  }, [sub])

  const deleteSubscriber = async (data) => {
    await deleteSubscriberDB(data)
    await loadSubscibersPoste(data.table, data.setList);
    setShowAlert('La personne a bien été supprimée');
    setModalDelete(false);
  }

  if (loading) {
    return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <>
      <div className="container mt-2">
        <h1 className='fw-bold border border-2 border-dark text-center text-white border bg-cdl-primary p-2 rounded'>RANDO Côte Des Légendes VTT</h1>

        <div className='shadow p-3 mt-4 mb-5 bg-cdl-secondary rounded text-center'>
          <h2 className='my-0'>LESNEVEN le dimanche 09 JUIN 2024</h2>
          <p className='my-0'>Le Côte Des Légendes VTT organise sa 22ème rando</p>
          <p className='my-0'>Pour la réussite d'une belle journée, la mobilisation de tous est indispensable.</p>
        </div>

        {showAlert && (
          <Alert className='sticky-top' variant="success" onClose={() => setShowAlert(false)} dismissible>
            {showAlert}
          </Alert>
        )}

        <div className='shadow p-3 mb-5 bg-white rounded'>

          <SubTitle title={'Traçage'} />

          <Inscription title={'Vendredi 07 juin'} secondTitle={'Traçage (jusqu\'à 16h00 repas midi fourni par le club)'} badge={'RDV 8h30'} setModal={setVendredi} poste={'Vendredi'} datas={vendrediList} deleteSubsciber={setModalDelete} table='vendredis' setList={setVendrediList} />

          <hr className='divider' />

          <Inscription title={'Samedi 08 juin'} secondTitle={'Traçage matinée + sortie sur circuit après-midi'} badge={'RDV 8h30'} setModal={setSamedi} datas={samediList} poste={'Samedi'} deleteSubsciber={setModalDelete} table='samedis' setList={setSamediList} />


          <SubTitle title={'Liste des postes le dimanche'} subtitle={'Rendez vous à partir de 6h30'} />


          <Inscription title={'Ouverture des circuits'} secondTitle={'4 vététistes'} badge={'RDV 7h15'} setModal={setOuverture} datas={ouvertureList} deleteSubsciber={setModalDelete} poste={'Ouverture'} table='ouvertures' setList={setOuvertureList} />

          <hr className='divider' />

          <Inscription title={'Parking'} secondTitle={'2 ou 3 personnes'} badge={'RDV 7h00 impérativement'} setModal={setParking} datas={parkingList} deleteSubsciber={setModalDelete} poste={'Parking'} table='parkings' setList={setParkingList} />

          <hr className='divider' />

          <Inscription title={'Inscriptions'} secondTitle={'4 personnes'} badge={'RDV 7h15'} setModal={setInscriptions} datas={inscriptionsList} deleteSubsciber={setModalDelete} poste={'Inscriptions'} table='inscriptions' setList={setInscriptionsList} />

          <hr className='divider' />

          <Inscription title={'Sécurité'} secondTitle={'Sur site'} setModal={setSecurity1} datas={security1List} deleteSubsciber={setModalDelete} table='security_sites' poste={'Sécurité site'} setList={setSecurity1List} />

          <hr className='divider' />

          <Inscription title={'Sécurité'} secondTitle={'Sur les circuits'} setModal={setSecurity2} datas={security2List} deleteSubsciber={setModalDelete} table='security_circuits' poste={'Sécurité circuits'} setList={setSecurity2List} />

          <hr className='divider' />

          <Inscription title={'Sécurité'} secondTitle={'Sur les passages sécurisé'} setModal={setSecurity3} datas={security3List} deleteSubsciber={setModalDelete} table='security_passages' poste={'Sécurité passage'} setList={setSecurity3List} />

          <hr className='divider' />

          <Inscription title={'Station de lavage'} setModal={setLavage} datas={lavageList} deleteSubsciber={setModalDelete} table='lavages' setList={setLavageList} poste={'Station lavage'} />

          <hr className='divider' />

          <Inscription title={'Confection des casse-croutes'} secondTitle={'6 personnes'} badge={'RDV 8h00 / 8h30'} setModal={setSandwich} datas={sandwichList} deleteSubsciber={setModalDelete} poste={'Confection sandwiches'} table='sandwiches' setList={setSandwichList} />

          <hr className='divider' />


          <SubTitle title={'Les ravitos'} subtitle={'Prévoir : portable, couteaux, plats, poubelles, boite à outils ...'} />


          <Inscription title={'Ravito n°1'} secondTitle={'5 personnes'} poste={'Ravito n°1'} setModal={setRavito1} datas={ravito1List} deleteSubsciber={setModalDelete} table='first_ravitos' setList={setRavito1List} />

          <hr className='divider' />

          <Inscription title={'Ravito n°2'} secondTitle={'5 personnes'} poste={'Ravito n°2'} setModal={setRavito2} datas={ravito2List} deleteSubsciber={setModalDelete} table='second_ravitos' setList={setRavito2List} />

          <hr className='divider' />

          <Inscription title={'Ravito n°3'} secondTitle={'5 personnes'} poste={'Ravito n°3'} setModal={setRavito3} datas={ravito3List} deleteSubsciber={setModalDelete} table='third_ravitos' setList={setRavito3List} />

          <hr className='divider' />

          <Inscription title={'Cochon grillé'} setModal={setCochon} poste={'Cochon grillé'} datas={cochonList} deleteSubsciber={setModalDelete} table='cochons' setList={setCochonList} />

          <p className='fw-bold m-0'>Veuillez répondre RAPIDEMENT pour faciliter l&apos;organisation ...</p>
          <p className='fw-bold m-0'>A tous encore une fois MERCI !!!</p>

          <p className='fw-bold mt-4'>Jean-Noël</p>

          <a href={`${process.env.REACT_APP_URL_WEB}/pdf`} target='_blank' className="mt-4 btn btn-secondary" rel="noreferrer">Voir le PDF</a>
        </div>

      </div>

      {/* Modals */}
      <ModalCdl
        modalName={vendredi}
        setModalName={setVendredi}
        title={'Vendredi 07 juin'}
        setSub={setSub}
        table={'vendredis'}
        setList={setVendrediList}
      />

      <ModalCdl
        modalName={samedi}
        setModalName={setSamedi}
        title={'Samedi 08 juin'}
        setSub={setSub}
        table={'samedis'}
        setList={setSamediList}
      />

      <ModalCdl 
        modalName={ouverture}
        setModalName={setOuverture}
        title={'Ouverture des circuits'}
        setSub={setSub}
        table={'ouvertures'}
        setList={setOuvertureList}
      />

      <ModalCdl 
        modalName={parking} 
        setModalName={setParking} 
        title={'Parking'} 
        setSub={setSub}
        table={'parkings'}
        setList={setParkingList}
      />

      <ModalCdl 
        modalName={inscriptions} 
        setModalName={setInscriptions} 
        title={'Inscriptions'} 
        setSub={setSub}
        table={'inscriptions'}
        setList={setInscriptionsList}
      />

      <ModalCdl 
        modalName={security1} 
        setModalName={setSecurity1} 
        title={'Sécurité sur site'} 
        setSub={setSub}
        table={'security_sites'}
        setList={setSecurity1List}
      />

      <ModalCdl 
        modalName={security2} 
        setModalName={setSecurity2} 
        title={'Sécurité sur les circuits'} 
        setSub={setSub}
        table={'security_circuits'}
        setList={setSecurity2List}
      />

      <ModalCdl 
        modalName={security3} 
        setModalName={setSecurity3} 
        title={'Sécurité les passages sécurisé'} 
        setSub={setSub}
        table={'security_passages'}
        setList={setSecurity3List}
      />

      <ModalCdl 
        modalName={lavage} 
        setModalName={setLavage} 
        title={'Station de lavage'} 
        setSub={setSub}
        table={'lavages'}
        setList={setLavageList}
      />

      <ModalCdl 
        modalName={sandwich} 
        setModalName={setSandwich} 
        title={'Confection des casse-croutes'} 
        setSub={setSub}
        table={'sandwiches'}
        setList={setSandwichList}
      />

      <ModalCdl 
        modalName={ravito1} 
        setModalName={setRavito1} 
        title={'Ravito n°1'} 
        setSub={setSub}
        table={'first_ravitos'}
        setList={setRavito1List}
      />

      <ModalCdl 
        modalName={ravito2} 
        setModalName={setRavito2} 
        title={'Ravito n°2'} 
        setSub={setSub}
        table={'second_ravitos'}
        setList={setRavito2List}
      />

      <ModalCdl 
        modalName={ravito3} 
        setModalName={setRavito3} 
        title={'Ravito n°3'} 
        setSub={setSub}
        table={'third_ravitos'}
        setList={setRavito3List}
      />

      <ModalCdl 
        modalName={cochon} 
        setModalName={setCochon} 
        title={'Cochon grillé'} 
        setSub={setSub}
        table={'cochons'}
        setList={setCochonList}
      />

      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Attention suppression !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalDelete && <p>Etes vous sur de vouloir supprimer {modalDelete.data.pseudo} de {modalDelete.table} ?</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalDelete(false)}>
            Annuler
          </Button>
          <button type="button" className='btn btn-cdl-primary' onClick={() => deleteSubscriber(modalDelete)}>Supprimer</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
