export default function useApi() {

  const loadSubscribers = async (table, setList) => {
    try {
      const result = await fetch(`${process.env.REACT_APP_URL_API}/subscribers/${table}`);
      const data = await result.json();
      setList(data);
      return data;
    } catch (err) {
      return err;
    }
  }

  const addSubscribers = async (data) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL_API}/subscribers`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      })
      const response = await res.json()
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  const deleteSubscriberDB = async (data) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL_API}/subscribers`, {
        method: 'DELETE',
        body: JSON.stringify({id: data.data.id, table: data.table}),
        headers: {
          "content-type": "application/json"
        }
      })
      await res.json()
    } catch (err) {
      console.log(err)
    }
  }

  return {
    loadSubscribers,
    addSubscribers,
    deleteSubscriberDB
  }
  
}
